<template>
    <b-card title="Cens" border-variant="primary" class="text-left">
        <b-row
            v-for="(requirement_document, index) in requirement_documents_cens"
            :key="index">
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Tipus" label-for="type">
                    <v-select
                        v-model="requirement_document.document_type_id"
                        :key="requirement_document.id"
                        :options="documentsTypesList"
                        label="name"
                        :disabled="requirement_document.requirement_id !== requirement.id  || readOnly"
                        :reduce="(option) => option.id"
                        @input="updateDocumentList"
                    />
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Document" label-for="date-document">
                    <b-form-file
                        v-model="requirement_document.document_file"
                        :disabled="requirement_document.requirement_id !== requirement.id  || readOnly"
                        placeholder="Tria un document o deixa'l anar aquí..."
                        drop-placeholder="Tria un document o deixa'l anar aquí..."
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Descripció" label-for="description">
                    <b-form-input v-model="requirement_document.description" name="description" :disabled="requirement_document.requirement_id !== requirement.id  || readOnly"
                                  placeholder="Descripció"/>
                </b-form-group>
            </b-col>
            <b-col v-if="requirement_document.document" lg="1" md="12" sm="12">
                <a :href="baseUrl+requirement_document.document" target="_blank">
                    <b-button
                        variant="outline-success"
                        class="mt-0 mt-md-2"
                    >
                        <feather-icon
                            icon="EyeIcon"
                            class="mr-25"

                        />

                    </b-button>
                </a>
            </b-col>
            <b-col v-if="requirement_document.requirement_id === requirement.id" lg="2" md="12" sm="12" class="mt-2">
                <b-button
                    :disabled="readOnly"
                    variant="outline-danger"
                    @click="removeItem(index, requirement_document.id)"
                >
                    <feather-icon
                        icon="TrashIcon"
                        class="mr-25"

                    />
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="pb-2">
                <b-button
                    :disabled="readOnly"
                    variant="primary"
                    @click="addNewRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir</span>
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BFormRadio,
    BFormCheckbox,
    BTab,
    BTabs,
    BFormFile,
    BIcon,
    BCardText,
    BCardGroup
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"

import ToastificationContent from "@core/components/toastification/ToastificationContent"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'requirements/getField',
    mutationType: 'requirements/updateField',
})

export default {
    name: "FormDocumentsCens",
    props: ['readOnly'],
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BCard,
        BOverlay,
        BFormRadio,
        BFormCheckbox,
        BTab,
        BTabs,
        BFormFile,
        BIcon,
        BCardText,
        BCardGroup,


        vSelect,
        flatPickr,

    },
    data() {
        return {
            SpanishLocale,
            clients: [],
            taxesTypes: [],
            documentsTypesList: [],
            baseUrl,
        }
    },
    mounted() {
        this.getDocumentsByType(4)
    },
    computed: {
        ...mapState('requirements', ['validation_requirement']),
        ...mapFields({
            requirement: 'requirement',
            requirement_documents_cens: 'requirement_documents_cens',
        }),

    },
    methods: {
        getDocumentsByType(typeId) {
            this.showLoading = true
            this.$store.dispatch('requirements/getDocumentsByType', {id: typeId})
            .then(response => {
                this.showLoading = false
                this.documentsTypesList = response
            })
            .catch(error => {
                console.log(error)
                this.showLoading = false
            })
        },
        addNewRow() {
            this.requirement_documents_cens.push({
                document_type_id: null,
                document_file: null,
                document: null,
                description: null,
                requirement_id: this.requirement.id
            })
        },
        removeItem(indexTax, documentId) {
            if (documentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el document?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('requirements/deleteRequirementDocument', documentId)
                        .then(response => {
                            this.showLoading = false
                            this.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            this.requirement_documents_cens.splice(indexTax, 1)

                        })
                        .catch(error => {
                            this.showLoading = false
                            this.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                            console.log(error)
                        })
                    }
                })
            } else {
                this.requirement_documents_cens.splice(indexTax, 1)
            }

        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
        updateDocumentList(){
            this.requirement_documents_cens.push({
                document_type_id: null,
                document_file: null,
                document: null,
                description: null,
                requirement_id: this.requirement.id
            })

            this.requirement_documents_cens.splice(this.requirement_documents_cens.length-1, 1)
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>