<template>
    <div>
        <b-card-group>
            <div class="col-sm-12  mt-1">
                <b-card
                v-if="requirement.status === this.getPhaseId('solved_accepted') || requirement.status === this.getPhaseId('solved_rejected') || requirement.status === this.getPhaseId('waiting_resolution')"
                title="Ressolució" border-variant="primary">
                <b-row>
                    <b-col lg="3" md="12" sm="12">
                        <b-form-group label="Document" label-for="date-document">
                            <b-form-file
                                v-model="requirement.resolution_document_file"
                                :disabled="readOnly"
                                placeholder="Tria un document o deixa'l anar aquí..."
                                drop-placeholder="Tria un document o deixa'l anar aquí..."
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" md="12" sm="12">
                        <b-form-group label="Comentaris" label-for="description">
                            <b-form-input v-model="requirement.resolution_comments" :disabled="readOnly"
                                          name="description"
                                          placeholder="Comentaris"/>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="requirement.resolution_document" lg="1" md="12" sm="12">
                        <a :href="baseUrl+requirement.resolution_document" target="_blank">
                            <b-button
                                variant="outline-success"
                                class="mt-0 mt-md-2"
                            >
                                <feather-icon
                                    icon="EyeIcon"
                                    class="mr-25"

                                />
                            </b-button>
                        </a>
                    </b-col>
                    <b-col v-if="requirement.resolution_document" lg="1" md="12" sm="12" class="mt-2">
                        <b-button
                            variant="outline-danger"
                            :disabled="readOnly"
                            @click="requirement.resolution_document_file = requirement.resolution_document = null "
                        >
                            <feather-icon
                                icon="TrashIcon"
                                class="mr-25"
                            />
                        </b-button>
                    </b-col>
                </b-row>

            </b-card>
            </div>
        </b-card-group>
        <b-card-group deck >
            <div class="col-sm-12">
                <b-card title="Requeriments" border-variant="primary">
            <b-row
                v-for="(requirement_document, index) in requirement_documents"
                :key="index">

                <b-col lg="6" md="12" sm="12">
                    <b-form-group label="Document" label-for="date-document">
                        <b-form-file
                            v-model="requirement_document.document_file"
                            :disabled="readOnly"
                            placeholder="Tria un document o deixa'l anar aquí..."
                            drop-placeholder="Tria un document o deixa'l anar aquí..."
                            browse-text="Seleccionar"
                        />
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group label="Descripció" label-for="description">
                        <b-form-input v-model="requirement_document.description" :disabled="readOnly"
                                      name="description" autocomplete="off"
                                      placeholder="Descripció"/>
                    </b-form-group>
                </b-col>
                <b-col v-if="requirement_document.document" lg="1" md="12" sm="12">
                    <a :href="baseUrl+requirement_document.document" target="_blank">
                        <b-button
                            variant="outline-success"
                            class="mt-0 mt-md-2"
                        >
                            <feather-icon
                                icon="EyeIcon"
                                class="mr-25"

                            />
                        </b-button>
                    </a>
                </b-col>
                <b-col lg="1" md="12" sm="12" class="mt-2">
                    <b-button
                        variant="outline-danger"
                        :disabled="readOnly"
                        @click="removeItem(index, requirement_document.id)"
                    >
                        <feather-icon
                            icon="TrashIcon"
                            class="mr-25"
                        />
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="pb-2">
                    <b-button
                        variant="primary"
                        :disabled="readOnly"
                        @click="addNewRow"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                        />
                        <span>Afegir</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
            </div>
        </b-card-group>
        <b-card-group deck>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 1)" class="col-sm-12  mt-1">
                <form-documents-i-r-p-f :readOnly="readOnly"/>
            </div>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 2)" class="col-sm-12 mt-1">
                <form-documents-i-v-a :readOnly="readOnly"/>
            </div>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 3)" class="col-sm-12 mt-1">
                <form-documents-i-s :readOnly="readOnly"/>
            </div>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 4)" class="col-sm-12 mt-1">
                <form-documents-cens :readOnly="readOnly"/>
            </div>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 5)" class="col-sm-12 mt-1">
                <form-documents-model347 :readOnly="readOnly"/>
            </div>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 6)" class="col-sm-12 mt-1">
                <form-documents-model180 :readOnly="readOnly"/>
            </div>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 7)" class="col-sm-12 mt-1">
                <form-documents-model190 :readOnly="readOnly"/>
            </div>
            <div v-if="this.checkTaxesForm(requirement.taxes_types , 8)" class="col-sm-12 mt-1">
                <form-documents-embargo :readOnly="readOnly"/>
            </div>
        </b-card-group>
    </div>

</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BFormRadio,
    BFormCheckbox,
    BTab,
    BTabs,
    BFormFile,
    BIcon,
    BCardText,
    BCardGroup
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"
import helpers from "@/resources/helpers/helpers"

import FormDocumentsIVA from "@/views/accounting/requirements/form/components/formDocumentsIVAView"
import FormDocumentsIRPF from "@/views/accounting/requirements/form/components/formDocumentsIRPFView"
import FormDocumentsIS from "@/views/accounting/requirements/form/components/formDocumentsISView"
import FormDocumentsCens from "@/views/accounting/requirements/form/components/formDocumentsCensView"
import FormDocumentsModel347 from "@/views/accounting/requirements/form/components/formDocuments347View"
import FormDocumentsModel180 from "@/views/accounting/requirements/form/components/formDocuments180View"
import FormDocumentsModel190 from "@/views/accounting/requirements/form/components/formDocuments190View"
import FormDocumentsEmbargo from "@/views/accounting/requirements/form/components/formDocumentsEmbargoView"
import {requirementsStatus} from "@/constants/accounting"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'requirements/getField',
    mutationType: 'requirements/updateField',
})

export default {
    props: ['readOnly'],
    name: "FormDocumentsRequirements",
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BCard,
        BOverlay,
        BFormRadio,
        BFormCheckbox,
        BTab,
        BTabs,
        BFormFile,
        BIcon,
        BCardText,
        BCardGroup,
        helpers,

        vSelect,
        flatPickr,

        FormDocumentsIVA,
        FormDocumentsIRPF,
        FormDocumentsIS,
        FormDocumentsCens,
        FormDocumentsModel347,
        FormDocumentsModel180,
        FormDocumentsModel190,
        FormDocumentsEmbargo,

    },
    data() {

        return {
            SpanishLocale,
            clients: [],
            documentsTypesList: [],
            baseUrl,
            requirementsStatus,
        }
    },
    mounted() {
        this.getDocumentsByType(0)
    },
    computed: {
        ...mapState('requirements', ['validation_requirement']),
        ...mapFields({
            requirement: 'requirement',
            requirement_documents: 'requirement_documents',
            requirement_documents_iva: 'requirement_documents_iva',
            requirement_documents_irpf: 'requirement_documents_irpf',
            requirement_documents_is: 'requirement_documents_is',
            requirement_documents_cens: 'requirement_documents_cens',
            requirement_documents_model_347: 'requirement_documents_model_347',
            requirement_documents_model_180: 'requirement_documents_model_180',
            requirement_documents_model_190: 'requirement_documents_model_190',
            requirement_documents_embargo: 'requirement_documents_embargo',
        }),
    },
    methods: {
        checkTaxesForm(array, value) {
            return array.some(object => object.id === value) || array.includes(value)
        },
        getDocumentsByType(typeId) {
            this.showLoading = true
            this.$store.dispatch('requirements/getDocumentsByType', {id: typeId})
            .then(response => {
                this.showLoading = false
                this.documentsTypesList = response
                this.idDocRec = response[0].id
            })
            .catch(error => {
                console.log(error)
                this.showLoading = false
            })
        },
        addNewRow() {
            this.requirement_documents.push({
                document_type_id: this.idDocRec,
                document_file: null,
                document: null,
                description: null,
                requirement_id: this.requirement.id
            })
        },
        getPhaseId(phaseName) {
            return this.requirementsStatus.find(element => element.value === phaseName).id
        },
        removeItem(indexTax, documentId) {
            if (documentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el document?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('requirements/deleteRequirementDocument', documentId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                         //   this.$root.$refs.mainFormRequirement.getRequirement()
                            window.location.reload()
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                            console.log(error)
                        })
                    }
                })
            } else {
                this.requirement_documents.splice(indexTax, 1)
            }

        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>